import React from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SupporterList from "../components/sponsors/SupporterList"
import SEO from "../components/SEO"

// const sponsors = [
//   {
//     name: `Sponsor 1`,
//     level: `Sponsor Level`,
//     img: `https://swervedesign.com/wp-content/uploads/2011/02/logo-ofntsc.jpg`,
//     key: 1,
//   },
//   {
//     name: `Sponsor 2`,
//     level: `Sponsor Level`,
//     img: `https://swervedesign.com/wp-content/uploads/2011/02/logo-ofntsc.jpg`,
//     key: 2,
//   },
//   {
//     name: `Sponsor 3`,
//     level: `Sponsor Level`,
//     img: `https://swervedesign.com/wp-content/uploads/2011/02/logo-ofntsc.jpg`,
//     key: 3,
//   },
//   {
//     name: `Sponsor 4`,
//     level: `Sponsor Level`,
//     img: `https://swervedesign.com/wp-content/uploads/2011/02/logo-ofntsc.jpg`,
//     key: 4,
//   },
//   {
//     name: `Sponsor 5`,
//     level: `Sponsor Level`,
//     img: `https://swervedesign.com/wp-content/uploads/2011/02/logo-ofntsc.jpg`,
//     key: 5,
//   },
//   {
//     name: `Sponsor 6`,
//     level: `Sponsor Level`,
//     img: `https://swervedesign.com/wp-content/uploads/2011/02/logo-ofntsc.jpg`,
//     key: 6,
//   },
//   {
//     name: `Sponsor 7`,
//     level: `Sponsor Level`,
//     img: `https://swervedesign.com/wp-content/uploads/2011/02/logo-ofntsc.jpg`,
//     key: 7,
//   },
//   {
//     name: `Sponsor 8`,
//     level: `Sponsor Level`,
//     img: `https://swervedesign.com/wp-content/uploads/2011/02/logo-ofntsc.jpg`,
//     key: 8,
//   },
// ]

const Sponsors = ({ data }) => {
  const { edges: sponsors } = data.sponsors
  const { edges: funders } = data.funders

  const backgroundImageFluid = data.desktop.childImageSharp.fluid
  const backgroundFluidImageStack = [
    backgroundImageFluid,
    `linear-gradient(rgba(var(--nofnec-mediumgreen), 0.6), rgba(var(--nofnec-mediumgreen), 0.6))`,
  ].reverse()

  return (
    // * Background image courtesy of Ricardo Frantz on Unsplash https://unsplash.com/@ricardofrantz
    <Layout>
      <SEO title="Our Supporters" />
      <BackgroundImage
        Tag="section"
        fluid={backgroundFluidImageStack}
        className="flex justify-center"
      >
        <div className=" w-5/6 md:w-2/3 my-10 flex flex-col items-center text-center">
          <h1 className="mt-20 mb-10 text-5xl">
            Thank you to our generous Funders and Sponsors for supporting
            NOFNEC!
          </h1>
          <section className="mb-5 w-full">
            <h1 className="my-3">Funders</h1>

            <SupporterList supporterArray={funders} />
          </section>
          <section className="mb-5 w-full">
            <h1 className="my-3">Sponsors</h1>
            <SupporterList supporterArray={sponsors} />
          </section>

          {/* <article className="sponsors__container-inner">
        {sponsorsList.map((eachSponsor, key) => {
          return (
            <article
              className="sponsors__container-item"
              key={eachSponsor.node.id}
            >
              <div>
                <img
                  src={eachSponsor.img}
                  alt="a feather with a blue circle"
                  className="sponsors__logo"
                />
                <div className="sponsors__container-item-info">
                  <h1 className="sponsors__container-item-title">
                    {eachSponsor.node.orgInfo.orgName}
                  </h1>
                  <p className="sponsors__container-item-info-p">
                    {eachSponsor.level}
                  </p>
                </div>
              </div>
            </article>
          )
        })}
      </article> */}
          <section className="mt-5">
            <div className="flex flex-col  items-center row-gap-3">
              <p className="font-bold text-3xl leading-9">
                Interested in supporting NOFNEC?
              </p>
              <Link to="/how-to-support-nofnec" className="btn">
                Learn More
              </Link>
            </div>
          </section>
        </div>
      </BackgroundImage>
    </Layout>
  )
}

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "lakeBG.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 3840, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sponsors: allSanitySponsor(
      filter: { funderOrSponsor: { eq: "sponsor" }, active: { eq: true } }
    ) {
      edges {
        node {
          ...SponsorInfoFragment
        }
      }
    }
    funders: allSanitySponsor(
      filter: { funderOrSponsor: { eq: "funder" }, active: { eq: true } }
    ) {
      edges {
        node {
          ...SponsorInfoFragment
        }
      }
    }
  }
`

export default Sponsors
