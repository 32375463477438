import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const SupporterList = ({ supporterArray = [] }) => {
  const data = useStaticQuery(graphql`
    query {
      nofnecLogo: file(relativePath: { eq: "NOFNEC_2020.png" }) {
        id
        childImageSharp {
          fixed(width: 300, fit: CONTAIN, cropFocus: NORTH) {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div class="grid text-center gap-y-5 ">
      {supporterArray.length > 0 ? (
        supporterArray.map(supporter => {
          const supporterLogoFixed = supporter.node.orgInfo.logo
            ? supporter.node.orgInfo.logo.asset.fixed
            : null

          const supporterLogoFluid =
            supporter?.node?.orgInfo?.logo?.asset?.fluid || null
          const orgInfo = supporter.node.orgInfo
          const { url = "#", orgName } = orgInfo
          return (
            <div
              key={supporter.node.id}
              className="flex flex-col  items-center  "
            >
              <a
                href={supporter.node.orgInfo.url}
                target="_blank"
                rel="noopener noreferrer"
                className=" bg-white rounded-md px-3 py-3"
                style={{ minWidth: "300px" }}
              >
                <Img
                  fluid={supporterLogoFluid}
                  alt={`${orgName} logo`}
                  className="mb-2"
                />

                <p className="text-xl md:text-3xl m-0 text-center italic">
                  {supporter.node.orgInfo.orgName}
                </p>
              </a>
            </div>
          )
        })
      ) : (
        <div>To Be Determined</div>
      )}
    </div>
  )
}

// Documentation on graphql fragments: https://www.gatsbyjs.com/docs/using-graphql-fragments/
export const query = graphql`
  fragment SponsorInfoFragment on SanitySponsor {
    id
    orgInfo {
      orgName
      url
      logo {
        asset {
          fluid(maxWidth: 400) {
            ...GatsbySanityImageFluid
            src
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
            src
          }
        }
      }
    }
  }
`

export default SupporterList
